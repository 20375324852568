.apartment-card {
  display: flex;
  flex-direction: column;

  @include screen-sm-min {
    flex-direction: row;
  }

  &__price {
    font-size: 20px;
    line-height: 30px;
    color: $secondary;

    @include screen-sm-min {
      font-size: 25px;
    }

    &__discount {
      &__text {
        font-size: $font-xs;
        position: relative;

        &:after {
          bottom: 5px;
          content: '';
          height: 1px;
          left: 0px;
          position: absolute;
          width: calc(100% - 10px);
          background: gray;
          transform: rotate(-9deg);
        }
      }

      &__percentage {
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: $danger;
        color: white;
        padding: 3px 5px;
        font-size: $font-sm;
        font-weight: 600;
        margin-bottom: 5px;
        display: inline-block;
        line-height: 1;
      }
    }

    &__per-person {
      margin-top: -2px;
      margin-bottom: 5px;

      @include screen-sm-min {
        margin-bottom: 0;
      }
    }

    &__wrapper-base {
      max-width: 125px;
      background-color: white;
      justify-content: center;
      border-radius: 4px;
      padding: 10px;

      @include screen-sm-min {
        padding-top: 0;
        max-width: 125px;
      }
    }

    &__wrapper-inside-gallery {
      position: absolute;
      right: 10px;
      bottom: -40px;
      line-height: 1;
      z-index: 5;
      text-align: center;
      transition: background-color 200ms linear;

      @include screen-sm-min {
        display: none;
      }
    }

    &__wrapper-inside-card {
      text-align: right;
      max-width: 140px;
      position: absolute;
      right: 5px;
      top: 40px;
      transition: background-color 200ms linear;
      display: none;

      @include screen-sm-min {
        display: block;
      }
    }
  }

  &__description {
    margin-left: 0;
    width: 100%;

    @include screen-xs-min {
      width: 65%;
    }
  }

  &.is-selected {
    .apartment-card__price {
      color: $danger;

      &__per-person {
        color: $danger !important;
      }
    }
  }

  &.is-disabled {
    .card__content {
      border-color: #e6ecef;
    }

    .apartment-card__gallery,
    .apartment-card__description {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &.is-occupied {
    pointer-events: none;
    opacity: 0.5;
  }

  &__gallery {
    width: 100%;

    @include screen-sm-min {
      width: 35%;
    }
  }

  .card__content {
    width: 100%;

    @include screen-sm-min {
      width: 66%;
    }
  }

  &__label {
    margin-bottom: 10px;

    @include screen-xl-min {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.card-banner {
  height: 100%;
  width: 100%;
  background-color: #ffffffa8;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;

  &__content {
    gap: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 20px;
    background-color: #576067;
    z-index: 4;
    border-radius: 4px;
    min-width: 70%;

    &.is-with-bottom-banner {
      top: calc(50% - 13px);
    }

    @include screen-sm-max {
      width: 90%;
    }
  }
}

.image-gallery {
  &,
  .image-gallery-content,
  .image-gallery-swipe,
  .image-gallery-slide,
  .image-gallery-slide-wrapper {
    height: 100%;
  }

  .image-gallery-right-nav,
  .image-gallery-left-nav {
    padding: 20px 10px;
  }

  .image-gallery-svg {
    max-height: 60px;
    max-width: 40px;

    @include screen-sm-min {
      max-height: 40px;
      max-width: 20px;
    }
  }

  .image-gallery-thumbnail {
    border: 2px solid white;
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 8px;
  }

  .image-gallery-thumbnails {
    position: relative;
    overflow: initial;
    z-index: 2;
  }

  .image-gallery-thumbnails-container {
    margin-top: -40px;
  }

  .image-gallery-slides {
    height: 100%;
    z-index: 2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &--rounded-lg-left {
    @include screen-sm-min {
      .image-gallery-slides {
        border-top-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    height: 100%;
  }

  .image-gallery-index {
    top: auto;
    bottom: 16px;
    left: 16px;
    width: fit-content;
    padding: 5px 10px;
    font-size: $font-sm;
    font-weight: 500;
  }

  .apartment-details-gallery {
    overflow: hidden;

    .image-gallery-content .image-gallery-slide .image-gallery-image {
      height: 390px;
    }

    @include screen-xl-max {
      .image-gallery-index {
        bottom: 50px;
      }
    }
  }
}

.apartment-attributes-tooltip {
  width: 200px;
  padding: 0 15px 10px 15px;

  &__icon {
    font-size: 50px;
    height: 60px;
  }
}

;@import "sass-embedded-legacy-load-done:99";