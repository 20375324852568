.card {
  position: relative;
  border: none;
  transition: background-color 200ms linear;

  &__content {
    width: 100%;
    border: 1px solid #aaafb3;
    border-top: none;
    border-radius: 0 0 4px 4px;

    @include screen-sm-min {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: none;
      border-top: 1px solid #aaafb3;
      border-radius: 0 4px 4px 0;
    }
  }

  &__title {
    z-index: 5;
    position: relative;

    @include screen-xs-max {
      max-width: calc(100vw - 195px);
    }
  }

  &__selection-indicator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    height: 0;
    transition: height 100ms ease-in-out;
    background-color: $light-blue;
    border-radius: 3px 0 3px 0;
  }

  &__image {
    border-radius: 2px;
    object-fit: cover;
    max-height: 200px;

    @include screen-sm-min {
      width: 30%;
    }
  }

  &.is-selected {
    .card {
      &__selection-indicator {
        height: 25px;
        padding-right: 5px;
        padding-bottom: 5px;
      }

      &__title {
        color: $danger !important;
      }
    }
  }
}

.card-improvement {
  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 40px;
    margin-bottom: 0;

    @include screen-xs-max {
      min-height: auto;
    }
  }

  &.is-md-full-width {
    @include screen-sm-min {
      display: grid !important;
      grid-template-columns: 40% 60%;

      .card__content {
        border: 1px solid #aaafb3;
        border-left: none;
        border-radius: 0 3px 3px 0;
        padding: 0 !important;
      }

      .scrollable-section__card__image {
        border-radius: 3px 0 0 3px;
      }
    }
  }

  .card__content {
    border: 1px solid #aaafb3;
    border-top: none;
    border-radius: 0 0 3px 3px;
    background-color: #f6fafe;

    @include screen-sm-min {
      padding: 0 !important;
    }
  }

  &.is-selected.is-md-full-width {
    .card__content {
      @include screen-md-min {
        border-left: none;
        border-top: 2px solid $danger;
      }
    }
  }

  &.is-low-image {
    .scrollable-section__card__image {
      max-height: 130px;
    }
  }

  &.stay-option-card {
    .card__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:116";