.password-form-group {
  &__visibility-icon {
    cursor: pointer;
    font-size: $font-lg;
    color: $text-muted;
    position: absolute;
    right: 10px;
    top: 37px;
    transition: right 100ms linear;
    all: unset;

    @include screen-xl-min {
      max-width: 980px;
    }

    &.is-password-check-eye-moved {
      right: 15px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:145";