html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: $light-azure !important;
  font-size: 13px;

  * {
    font-family: museo_sans, sans-serif !important;
  }

  @include screen-xl-min {
    font-size: 14.5px;
  }

  @include screen-xs-min {
    font-size: 12.5px;
  }

  &.block-scroll {
    overflow: hidden;
    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  @include screen-lg-max {
    background-color: white !important;
  }
}

.bg-light-azure {
  background-color: $light-azure;
}

.bg-light-blue {
  background-color: $light-blue !important;
}

.bg-alice-blue {
  background-color: $alice-blue;
}

.bg-dark-gradient {
  background: $dark-gray-gradient;
}

.bg-gold-gradient {
  background: $gold-gradient;
}

.bg-very-light-gray {
  background-color: $very-light-gray;
}

.bg-purple {
  background-color: $purple !important;
}

.text-black-olive {
  color: $black-olive;
}

.text-darker-gray {
  color: $darker-gray;
}

.text-light-muted {
  color: $light-muted;
}

.text-disabled {
  color: rgba(black, 0.2);
}

.text-purple {
  color: $purple;
}

.font-size-xxs {
  font-size: $font-xxs;
}
.font-size-xs {
  font-size: $font-xs;
}

.font-size-sm {
  font-size: $font-sm;
}

.font-size-md {
  font-size: $font-md !important;
}

.font-size-lg {
  font-size: $font-lg;
}

.font-size-xl {
  font-size: $font-xl !important;
}

.font-size-xxl {
  font-size: $font-xxl;
}

.font-size-icon {
  font-size: 2.4rem;
}

.lh-0 {
  line-height: 0;
}

.lh-normal {
  line-height: normal !important;
}

.lh-initial {
  line-height: initial !important;
}

.btn-outline-primary:hover,
.btn-outline-secondary:hover {
  color: white !important;
}

.btn-dark-grey-gradient {
  background: $dark-gray-gradient;
  transition: background 100ms linear;

  &:hover {
    background: linear-gradient(rgba(96, 106, 114, 0.95), rgba(68, 76, 82, 0.95));
  }
}

.btn-thin {
  height: 40px;
}

.btn-tall {
  height: fit-content;
  min-height: 47px !important;
}

.btn-unstyled {
  border: none;
  background: none;
  padding-inline: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.px-xl-6 {
  @include screen-xl-min {
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

.border-xl-0 {
  @include screen-xl-min {
    border: none !important;
  }
}

.border-top-right-radius-0 {
  border-top-right-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.fw-semi-bold {
  font-weight: 500 !important;
}

.w-inherit {
  width: inherit;
}

.w-xl-75 {
  width: 100%;

  @include screen-xl-min {
    width: 75% !important;
  }
}

.w-responsiveness-btn {
  width: 100% !important;
  min-height: 47px;

  @include screen-xl-min {
    padding: 5px 30px !important;
    width: fit-content !important;
  }
}

.z-index-5 {
  z-index: 5;
}

.z-index-1 {
  z-index: 1;
}

.underline-shadow {
  background-size: 1px 1em;
  box-shadow: inset 0 -0.175em white, inset 0 -8px rgba($gold, 0.3);
  display: inline;

  &.gold {
    box-shadow: inset 0 -8px rgba($gold, 0.3);
  }
}

.bg-shadow {
  box-shadow: 0 0 9px -2px rgba(66, 68, 90, 0.28);
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.label-success {
  border: 1px solid rgba(57, 183, 37, 0.2);
  background: rgb(216, 235, 226);
  border-radius: 4px;
  padding: 1px 10px;
}

.mt-form-input {
  margin-top: 21px;
}

;@import "sass-embedded-legacy-load-done:7";