.check-button {
  background-color: $light-blue;
  color: #969da3;
  border: 2px solid #c8ced4;
  padding: 15px 20px;
  border-radius: 0.25rem;

  &__icon {
    display: none;
    position: absolute;
    z-index: 1;
    left: 51%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  &__icon-wrapper {
    position: relative;
    width: 30px;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #9099a1;
      background-color: #f8f8f8;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    text-align: initial;
  }

  &.is-active {
    color: white;
    background-color: $primary;
    border: 2px solid $primary;

    .check-button__icon-wrapper {
      &:after {
        background-color: $primary;
        border-color: white;
      }
    }

    .check-button__icon {
      display: block;
    }
  }

  &__discount {
    background-color: $danger;
    position: absolute;
    right: -2px;
    top: -2px;
    padding: 0 4px;
    border-radius: 0 4px;
  }
}

;@import "sass-embedded-legacy-load-done:142";