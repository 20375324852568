.circle-outline-radio-button {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: initial;
  color: $text-muted;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  padding-left: 0;

  &--tall {
    height: 59px;

    .form-check-input {
      margin: 0 20px 0 0;
    }
  }

  @include screen-xl-min {
    height: 44px;
  }

  .form-check-input {
    cursor: pointer;
    margin: 0;
    min-width: 25px;
    min-height: 25px;
    position: relative;

    @include screen-xl-min {
      min-width: 20px;
      min-height: 20px;
    }

    &:checked {
      background-image: none;

      + .form-check-label {
        color: $primary;
      }

      &:before {
        content: '';
        position: absolute;
        left: -5px;
        top: -5px;
        border-radius: 50%;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 2px solid $primary;
      }
    }
  }

  .form-check-label {
    width: 100%;
    padding-left: 10px;
    font-size: 1.1rem;

    @include screen-xl-min {
      font-size: 1rem;
      color: $darker-gray;
      height: inherit;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .form-check {
    margin-bottom: 0;
    padding-left: 0;
  }

  &__check-icon {
    pointer-events: none;
    position: absolute;
    left: 3.5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: white;

    @include screen-xl-min {
      font-size: 14px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:144";